/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';
import twitter from '../assets/twitter.png';
import '../App.css';
import Footer from '../Components/footer';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import $ from 'jquery';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import os from '../assets/os.png';
import up from '../assets/flash.png';
import nft2 from '../assets/pepe.png';
import discord from '../assets/discord.png';
import logoUp from '../assets/logo-up.png';
import { baseGoerli } from 'wagmi/chains';
import Countdown from 'react-countdown';
import '../counter.css';
import logoPic from '../assets/logo2.png';

var Scroll = require('react-scroll');
/*
$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 2000);
});

<div class="page-loader">
					<div class="containerLoad">
						<div class="ring">
							<div class="h1Load">Loading...</div>
						</div>
					</div>
				</div>*/
const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload(true);
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;
var mintCost = 0;
var fullTotalSupply = 0;

const opensea = () => {
	window.open("#");
}

const home = () => {
	window.open("https://frogchain.pages.dev/");
}

const mintSite = () => {
	/*window.open("/https://titantakeovermint.pages.dev/");*/
	window.open("#");

}

const stakeSite = () => {
	//window.open("https://titantakeoverstake.pages.dev/");
	window.open("https://stake-frogchain.pages.dev");

}

const gameSite = () => {
	//window.open("https://titantakeoverstake.pages.dev/");
	window.open("https://battle-frogchain.pages.dev/");

}

const tweet = () => {
	window.open("#");
}

const discrd = () => {
	window.open("#");
}

let ABI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimals",
				"type": "uint256"
			}
		],
		"name": "setDecimals",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minimumNFTsHolding",
				"type": "uint256"
			}
		],
		"name": "setMinimumNFTsHolding",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenAmount",
				"type": "uint256"
			}
		],
		"name": "setTokenAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_claimOpen",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_switchOption1",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_switchOption2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_token_Contract",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "allocated",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "allocations",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimOpen",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minimumNFTsHolding",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "share",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "switchOption1",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "switchOption2",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "withdrawn",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let address = "0x115c176b93D58c28b4b5933EA1055bB660E00417";

const Mint = () => {

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const publicClient = createPublicClient({
		chain: baseGoerli,
		transport: http()
	})

	const [_totalSupply, settotalSupply] = useState(0)
	const [statusError, setstatusError] = useState("")
	const [statusLoading, setstatusLoading] = useState("")
	const [success, setsuccess] = useState("")
	const [nftMintingAmount, setnftMintingAmount] = useState(1)
	const [_publicMintMsg, set_publicMintMsg] = useState("Mint Here")
	const [_cost, set_publicSaleCost] = useState("")
	const [_wlcost, set_wlcost] = useState("")
	const [_max_per_wallet, set_max_per_wallet] = useState(10)
	const [_owner, set_owner] = useState("")
	const [myNFTWallet, setmyNFTWallet] = useState(0)
	const [_whitelist_mint_status, set_whitelist_mint_status] = useState("")
	const [_public_mint_status, set_public_mint_status] = useState("")
	const [_whitelistLimitPerWallet, set_whitelistLimitPerWallet] = useState("")
	const [_whitelistCount, set_whitelistCount] = useState("")
	const [_whitelistLimit, set_whitelistLimit] = useState("")
	const [_MAX_SUPPLY, set_MAX_SUPPLY] = useState("")
	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_connected, setConnected] = useState(false)
	const [_freeMinLimit, set_OverallFreeMintLimit] = useState(600);
	const [_stateNow, set_stateNow] = useState(Date.now());

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}
	//........................................//
	const contract = {
		address: address,
		abi: ABI
	}

	async function handleConnect() {
		if (chain.id !== 84531) {
			switchNetwork(84531)
		}

		var data = await getBalance();
		setmyNFTWallet(Number(data.data));
		console.log("myNFTWallet :" + data.data);
		setConnected(true);
	}

	const { refetch: getTotalSupply } = useContractRead({
		...contract,
		functionName: 'totalSupply',
	})

	const { refetch: getCost } = useContractRead({
		...contract,
		functionName: 'publicSaleCost',
	})

	const { refetch: getBalance } = useContractRead({
		...contract,
		functionName: 'balanceOf',
		args: [walletAddress ? walletAddress : '0x']
	})


	useEffect(() => {

		async function fetchData() {
			var data = await getTotalSupply();

			settotalSupply(Number(data.data))
			console.log("totalSupplyFromUseffect : " + data.data)

		}
		async function fetchData2() {

			var data1 = await getBalance();
			setmyNFTWallet(Number(data1.data));
			console.log("myNFTWallet :" + data1.data);
		}

		async function fetchData3() {

			var data2 = await getCost();
			set_publicSaleCost(Number(data2.data) / 10 ** 18);
			console.log("publicSaleCost :" + data2.data);
		}

		if (_connected) {
			fetchData();
			fetchData2();
			fetchData3();
		}

		// eslint-disable-next-line no-use-before-define
	}, [_connected, getBalance, getCost, getTotalSupply]);

	const { data, refetch, isSuccess } = useContractReads({
		contracts: [
			{ ...contract, functionName: 'totalSupply' },
			{ ...contract, functionName: 'max_per_wallet' },
			{ ...contract, functionName: 'wlCost' },
			{ ...contract, functionName: 'owner' },
			{ ...contract, functionName: 'publicSaleCost' },
			{ ...contract, functionName: 'whitelistCount' },
			{ ...contract, functionName: 'whitelistLimit' },
			{ ...contract, functionName: 'MAX_SUPPLY' },
			{ ...contract, functionName: 'whitelistLimitPerWallet' },
			{ ...contract, functionName: 'whitelist_mint_status' },
			{ ...contract, functionName: 'public_mint_status' },
			{ ...contract, functionName: 'overallFreeMintLimit' }

		]
	},)

	useMemo(() => {

		if (isSuccess === true) {
			settotalSupply(Number(data[0].result))
			set_max_per_wallet(Number(data[1].result))
			//set_wlcost(formatEther(data[2].result))
			set_wlcost(Number(data[2].result) / 10 ** 18); // Convert WEI to ETH
			set_owner(data[3].result)
			//set_publicSaleCost(formatEther(data[4].result))
			set_publicSaleCost(Number(data[4].result) / 10 ** 18); // Convert WEI to ETH
			set_whitelistCount(data[5].result)
			set_whitelistLimit(data[6].result)
			set_MAX_SUPPLY(data[7].result)
			set_whitelistLimitPerWallet(data[8].result)
			set_whitelist_mint_status(data[9].result)
			set_public_mint_status(data[10].result)
			set_OverallFreeMintLimit(data[11].result)

			console.log("_totalSupply: " + _totalSupply)
			console.log("walletAddress: " + walletAddress)
			console.log("cost: " + _cost)
		}
	}, [_totalSupply, data, isSuccess, walletAddress])



	const { writeAsync } = useContractWrite({
		...contract,
		functionName: 'mint',
		onError(error) {
			if (error.message.includes('balance')) {
				setstatusError(true)
				setstatusLoading(false)
			}
		}
	})
	//........................................//

	async function onPlus() {

		console.log("_whitelist_mint_status :" + _whitelist_mint_status);
		console.log("_public_mint_status :" + _public_mint_status);
		var nftIhave = nftMintingAmount + myNFTWallet;


		console.log("myNFTWallet : " + myNFTWallet);
		console.log("nftMintingAmount : " + nftMintingAmount);


		console.log("nftIhave : " + nftIhave);


		if (_public_mint_status === true) {
			console.log("2");

			

			var remainingInTheCurrentSession;

			if ((_totalSupply) >= 0 && (_totalSupply) < 500) {
				set_publicSaleCost(0.01);

				remainingInTheCurrentSession = 500 - _totalSupply;		
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);
				
			} else if ((_totalSupply) >= 501 && (_totalSupply) < 1000) {
				set_publicSaleCost(0.02);

				remainingInTheCurrentSession = 1000 - _totalSupply;
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);

			} else if ((_totalSupply) >= 1001 && (_totalSupply) < 2000) {
				set_publicSaleCost(0.03);

				remainingInTheCurrentSession = 2000 - _totalSupply;
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);

			} else if ((_totalSupply) >= 2001 && (_totalSupply) < 3000) {
				set_publicSaleCost(0.04);

				remainingInTheCurrentSession = 3000 - _totalSupply;
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);

			} else if ((_totalSupply) >= 3001 && (_totalSupply) < 4000) {
				set_publicSaleCost(0.05);

				remainingInTheCurrentSession = 4000 - _totalSupply;
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);

			} else if ((_totalSupply) >= 4001 && (_totalSupply) < 5000) {
				set_publicSaleCost(0.06);

				remainingInTheCurrentSession = 5000 - _totalSupply;
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);

			} else if ((_totalSupply) >= 5001 && (_totalSupply) < 6000) {
				set_publicSaleCost(0.07);

				remainingInTheCurrentSession = 6000 - _totalSupply;
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);

			} else if ((_totalSupply) >= 6001 && (_totalSupply) < 7000) {
				set_publicSaleCost(0.08);

				remainingInTheCurrentSession = 7000 - _totalSupply;
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);

			} else if ((_totalSupply) >= 7001 && (_totalSupply) < 8000) {
				set_publicSaleCost(0.09);

				remainingInTheCurrentSession = 8000 - _totalSupply;
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);

			} else if ((_totalSupply) >= 8001 && (_totalSupply) < 9000) {
				set_publicSaleCost(0.1);

				remainingInTheCurrentSession = 9000 - _totalSupply;
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);

			} else if ((_totalSupply) >= 9001 && (_totalSupply) < 10000) {
				set_publicSaleCost(0.11);

				remainingInTheCurrentSession = 10000 - _totalSupply;
				console.log("remainingInTheCurrentSession : " + remainingInTheCurrentSession);

			} else {


			}

			if(remainingInTheCurrentSession < _max_per_wallet){

				if(nftIhave < remainingInTheCurrentSession){

					setnftMintingAmount(nftMintingAmount + 1);

				}

			}else {

				if(nftIhave < _max_per_wallet){

					setnftMintingAmount(nftMintingAmount + 1);

				}

			}	

			console.log("mintCostB : " + mintCost);
		}

		}
	

	async function onMinus() {
		if (nftMintingAmount != 1) {
			setnftMintingAmount(nftMintingAmount - 1)
		}
	}


	async function onMint() {


		try {

			fullTotalSupply = (nftMintingAmount) + (_totalSupply);
			console.log("fullTotalSupply : " + fullTotalSupply);
			console.log("_owner : " + _owner);
			console.log("walletAddress : " + walletAddress);


			if (_owner === walletAddress) {

				mintCost = '0'

			} else {

				if (fullTotalSupply <= 0) {
					mintCost = '0'
				} else

					
						if (_public_mint_status === true) {
							//mintCost =  parseFloat(_cost)



							if ((_totalSupply + nftMintingAmount) >= 0 && (_totalSupply + nftMintingAmount) <= 500) {
								mintCost = nftMintingAmount * 0.01;
								set_publicSaleCost(0.01);
								
							} else if ((_totalSupply + nftMintingAmount) >= 501 && (_totalSupply + nftMintingAmount) <= 1000) {
								mintCost = nftMintingAmount * 0.02;
								set_publicSaleCost(0.02);

							} else if ((_totalSupply + nftMintingAmount) >= 1001 && (_totalSupply + nftMintingAmount) <= 2000) {
								mintCost = nftMintingAmount * 0.03;
								set_publicSaleCost(0.03);

							} else if ((_totalSupply + nftMintingAmount) >= 2001 && (_totalSupply + nftMintingAmount) <= 3000) {
								mintCost = nftMintingAmount * 0.04;
								set_publicSaleCost(0.04);

							} else if ((_totalSupply + nftMintingAmount) >= 3001 && (_totalSupply + nftMintingAmount) <= 4000) {
								mintCost = nftMintingAmount * 0.05;
								set_publicSaleCost(0.05);

							} else if ((_totalSupply + nftMintingAmount) >= 4001 && (_totalSupply + nftMintingAmount) <= 5000) {
								mintCost = nftMintingAmount * 0.06;
								set_publicSaleCost(0.06);

							} else if ((_totalSupply + nftMintingAmount) >= 5001 && (_totalSupply + nftMintingAmount) <= 6000) {
								mintCost = nftMintingAmount * 0.07;
								set_publicSaleCost(0.07);

							} else if ((_totalSupply + nftMintingAmount) >= 6001 && (_totalSupply + nftMintingAmount) <= 7000) {
								mintCost = nftMintingAmount * 0.08;
								set_publicSaleCost(0.08);

							} else if ((_totalSupply + nftMintingAmount) >= 7001 && (_totalSupply + nftMintingAmount) <= 8000) {
								mintCost = nftMintingAmount * 0.09;
								set_publicSaleCost(0.09);

							} else if ((_totalSupply + nftMintingAmount) >= 8001 && (_totalSupply + nftMintingAmount) <= 9000) {
								mintCost = nftMintingAmount * 0.1;
								set_publicSaleCost(0.1);

							} else if ((_totalSupply + nftMintingAmount) >= 9001 && (_totalSupply + nftMintingAmount) <= 10000) {
								mintCost = nftMintingAmount * 0.11;
								set_publicSaleCost(0.11);

							} else {
							//	mintCost = _cost * nftMintingAmount;
							}

							console.log("mintCostB : " + mintCost);
						}
					
			}

			mintCost = (mintCost * nftMintingAmount).toString();
			console.log("nftMintingAmountA : " + nftMintingAmount)

			console.log("mintCostA : " + mintCost);


			setstatusLoading(true)
			setstatusError(false)

			var res = await writeAsync({
				args: [nftMintingAmount],
				value: parseEther(mintCost)// mintCost
			})
			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)

			}
		}
		catch (e) {
			console.log(e)
			setstatusError(true)
			setstatusLoading(false)

		}
	}


	return (
		<div class="allWrap">

			<div class="light">



				<div class="cont2">

					<Element name="up">

						{_navbarOpen < 1 ?
							(<div class="miniBarMain">
								<div class="title" onClick={home}><img class="logoUp" src={logoUp} />FROG CHAIN</div>

								<div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
							</div>) :
							(<div class="miniBarMain">
								<div class="title" onClick={home}><img class="logoUp" src={logoUp} />FROG CHAIN</div>

								<div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
							</div>)}

						<div class="headers">

							<div class="h1">
								<div class="title" onClick={home}><img class="logoUp" src={logoUp} />FROG CHAIN</div>

								<div class="connect">
									<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550} onClick={home}>Home</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={mintSite}><span class="onPage">Mint</span></Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={stakeSite}>Staking</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={gameSite}>Battlefield</Link></div>
								</div>

								<div class="right">

									<div class="icons">
										<img onClick={opensea} src={os} />
										<img onClick={tweet} src={twitter} />
										<img onClick={discrd} src={discord} />
									</div>
									<div class="connect2">
										<Web3Button />
									</div>

								</div>


								<div class="connectMob">
									<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
								</div>



							</div>
						</div>

						{_navbarOpen > 0 ?
							(<div class="littleNav" data-aos="fade-left">

								<div ><Link activeClass="" id="fontSize2" to="story" spy={true} smooth={true} duration={550} onClick={home}>Home</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={mintSite}><span class="onPage">Mint</span></Link></div>
								<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={stakeSite}>Staking</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={gameSite}>Battlefield</Link></div>
								<div class="connect2">
									<Web3Button />
								</div>

							</div>) : null}
					</Element>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="firefly"></div>
					<div class="boxWrap2Mint">

						<span class="storyH2">Mint</span>

						<div class="mintDiv">
							<div class="pDiv"><img class="parrot" src={nft2} /></div>
							<div class="mintCon">
								<div class="totalSupply">{_totalSupply} / 10000</div>
								<div class="price"><div>Mint Price  {(_cost * nftMintingAmount).toFixed(3)} Ξ </div></div>

								<div class="minting_count_button">

									<div class="center">

										<button onClick={onMinus} class="btnfos-0-2" type="submit">-</button>

									</div>

									<div>
										<div class="nftminter2"><span class="nftamount">{nftMintingAmount}</span></div>
									</div>


									<div class="center">
										<button onClick={onPlus} class="btnfos-0-2" type="submit">+</button>

									</div>
								</div>


								{walletAddress === '' ?

									(<div class="mintbuttondiv">
										<button onClick={() => { open() }} class="btnfos-0-3">Connect</button>

									</div>) :
									(<div class="mintbuttondiv">
										{_connected ?
											(<div>
												{/* <form onSubmit={this.onSubmit2}> */}
												<button class="button-89" onClick={onMint}>
													{_publicMintMsg}</button>
												{/* </form> */}
											</div>) : (<div>
												{/* <form onSubmit={this.onSubmit2}> */}
												<button class="button-89" onClick={() => { open() }}>
													CONNECT</button>
												{/* </form> */}
											</div>)}

									</div>)}


								<div>

									{statusError ? (
										<div class="errorMessage">
											<div >Sorry, something went wrong <br /> please try again later</div>
										</div>)
										: null}

									{statusLoading ? (
										<div class="loadingContainer">
											<div class="loadingText">Minting your NFT</div>
										</div>)
										: null}

									{success ? (
										<div class="success-message">
											<h2>Successful NFT Minting!</h2>
											<p>Your NFT has been successfully minted.</p>
										</div>
									)
										: null}

								</div>

							</div>

						</div>

					</div>


				</div>


				<Footer />

			</div>

			<Link id="sticky-button" to="up" spy={true} smooth={true} duration={550}> <img class="stickyBtn2" src={up} /> </Link>

		</div >
	)

}
export default Mint;
